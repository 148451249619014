import * as clientToolImages from "src/pages/clientConnectivity/images";

export default function ClientToolsImage(props: {
  imageIcon: string;
  className?: string;
}) {
  const ImageComponent =
    clientToolImages[props.imageIcon as keyof typeof clientToolImages];

  if (!ImageComponent) {
    console.error("No image found with imageIcon = " + props.imageIcon);
    return null;
  }

  const defaultClasses = "client-connectivity-icon card-img-top img-thumbnail";

  const combinedClassName = props.className
    ? `${defaultClasses} ${props.className}`
    : defaultClasses;

  return <ImageComponent className={combinedClassName} />;
}
